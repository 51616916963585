import React from "react";
import {
  AppBar,
  Hidden,
  Toolbar,
  Typography,
  IconButton,
} from "@material-ui/core"
import {
  Menu as MenuIcon,
  SvgIconComponent,
} from "@material-ui/icons";
import {
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";
import { theme as koneTheme } from "@kone/ui-library";

import UserMenu from "../UserMenu";

export interface HeaderBarProps {
  handleSidebarToggle: () => void,
  isAuthenticated: boolean,
  logo?: string,
  onSignOut: () => void,
  sidemenuWidth: number,
  title?: string,
  titleIcon?: SvgIconComponent,
  userMenuTitle?: string,
};

const useStyles = (sidemenuWidth: number) => (
  makeStyles((theme: Theme) =>
    createStyles({
      appBar: {
        [theme.breakpoints.up("md")]: {
          width: `calc(100% - ${sidemenuWidth}px)`,
          marginLeft: sidemenuWidth,
        },
      },
      menuButton: {
        // marginRight: theme.spacing(2),
        // [theme.breakpoints.up("md")]: {
        //   display: "none",
        // },

        // TODO: Delete this line and uncomment lines above
        // if you need this menu become visible on mobile devices
        display: "none"
      },
      title: {
        flexGrow: 1,
      },
      headerbarIcon: {
        marginRight: "1rem",
      },
    }),
  )
);

const HeaderBar = (props: HeaderBarProps): JSX.Element => {
  const classes = useStyles(props.sidemenuWidth)(koneTheme);
  const getIcon = (Icon?: SvgIconComponent) => (
    Icon
      ? <Icon
        classes={{root: `headerbar-icon ${props.title?.toLowerCase()}-headerbar-icon`}}
        className={classes.headerbarIcon}
      />
      : null
  );

  const renderUserMenu = () => (
    <>
      <Hidden xsDown implementation="css">
        <UserMenu
          onSignOut={props.onSignOut}
          title={props.userMenuTitle}
        />
      </Hidden>
      <Hidden smUp implementation="css">
        <UserMenu onSignOut={props.onSignOut} />
      </Hidden>
    </>
  );

  return (
    <AppBar
      position="fixed"
      className={classes.appBar}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={props.handleSidebarToggle}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        { getIcon(props.titleIcon) }
        <Typography
          variant="h6"
          noWrap
          className={classes.title}
          classes={{root: `headerbar-title ${props.title?.toLowerCase()}-headerbar-title`}}
        >
          { props.title }
        </Typography>
        { props.isAuthenticated && renderUserMenu() }
        { props.logo && <img alt="" src={props.logo} /> }
      </Toolbar>
    </AppBar>
  );
};

export default HeaderBar;
