import React from "react";
import {
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import {
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";
import { theme as koneTheme } from "@kone/ui-library";

export interface LoadingProps {
  invisible?: boolean,
};

const Loading = (props: LoadingProps): JSX.Element => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      typography: {
        marginLeft: theme.spacing(3),
        color: "white"
      },
      backdrop: {
        zIndex: theme.zIndex.drawer + 1,
      }
    }),
  );

  const classes = useStyles(koneTheme);

  return (
    <Backdrop
      className={classes.backdrop}
      open={true}
      invisible={props.invisible}
    >
      <CircularProgress color="primary" />
    </Backdrop>
  );
};

export default Loading;
