import { render } from "react-dom";
import { WithAuthenticatorProps } from "@kone/ui-library";

import Swagger from "./containers/Swagger";

const props = {} as WithAuthenticatorProps;

render(
  <Swagger {...props} />,
  document.getElementById("root")
);
