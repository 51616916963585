"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const styles_1 = require("@material-ui/core/styles");
const Kone_Information = __importStar(require("./fonts/KONE_Information.woff2"));
const WHITE_100 = "#FFFFFF";
const GRAY_80 = "#262E33";
const GRAY_60 = "#566066";
const GRAY_50 = "#717A80";
const GRAY_40 = "#8C9499";
const GRAY_20 = "#C6CACC";
const GRAY_03 = "#F5F6F7";
const BLUE_SHADE_20 = "#005A94";
const BLUE_BASE = "#0071B9";
const BLUE_TINT_60 = "#99C6E3";
const ORANGE_BASE = "#FFA500";
const RED_BASE = "#C71F25";
const LIGHT_RED = "#FFE2E2";
const DARK_RED = "#FF0000";
const GREEN_BASE = "#58AB27";
const LIGHT_GREEN = "#DDEED3";
const LIGHT_BLUE = "#CCE2F1";
const MAIN_INFO_BLUE = "#2196f3";
const koneds_color_white_100 = WHITE_100;
const koneds_color_gray_80 = GRAY_80;
const koneds_color_gray_60 = GRAY_60;
const koneds_color_gray_50 = GRAY_50;
const koneds_color_gray_40 = GRAY_40;
const koneds_color_gray_20 = GRAY_20;
const koneds_color_gray_03 = GRAY_03;
const koneds_color_blue_shade_20 = BLUE_SHADE_20;
const koneds_color_blue_base = BLUE_BASE;
const koneds_color_blue_tint_60 = BLUE_TINT_60;
const koneds_color_orange_base = ORANGE_BASE;
const koneds_color_red_base = RED_BASE;
const koneds_color_red_light = LIGHT_RED;
const koneds_color_red_dark = DARK_RED;
const koneds_color_green_base = GREEN_BASE;
const koneds_color_bg_primary = WHITE_100;
const koneds_green_light = LIGHT_GREEN;
const koneds_blue_light = LIGHT_BLUE;
const koneds_main_info_blue = MAIN_INFO_BLUE;
const koneFont = {
    fontFamily: "Kone Information",
    fontStyle: "normal",
    fontWeight: 400,
    src: `
    local("Kone Information"),
    local("Kone Information Latin Regular"),
    url(${Kone_Information}) format("woff2")
  `,
};
const theme = styles_1.createMuiTheme({
    palette: {
        common: {},
        primary: {
            light: koneds_color_blue_tint_60,
            main: koneds_color_blue_base,
            dark: koneds_color_blue_shade_20,
            contrastText: koneds_color_white_100,
        },
        secondary: {
            main: koneds_color_gray_40,
            contrastText: koneds_color_white_100,
        },
        error: {
            main: koneds_color_red_base,
            light: koneds_color_red_light,
            dark: koneds_color_red_dark,
            contrastText: koneds_color_white_100,
        },
        warning: {
            main: koneds_color_orange_base,
            contrastText: koneds_color_gray_03,
        },
        success: {
            main: koneds_color_green_base,
            light: koneds_green_light,
            contrastText: koneds_color_gray_03,
        },
        background: {
            default: koneds_color_bg_primary,
        },
        info: {
            main: koneds_main_info_blue,
            light: koneds_blue_light,
        },
        text: {
            primary: koneds_color_gray_80,
            secondary: koneds_color_gray_60,
            disabled: koneds_color_gray_50,
        },
    },
    typography: {
        fontFamily: ["\"Kone Information\"", "Arial", "Roboto", "sans-serif"].join(","),
    },
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: "24",
                color: koneds_color_gray_80,
                backgroundColor: koneds_color_white_100,
                border: "1px solid " + koneds_color_gray_20,
                boxShadow: "0px 0px 3px 3px " + koneds_color_gray_40 + "40",
            },
            arrow: {
                "&::before": {
                    border: "1px solid " + koneds_color_gray_20,
                    boxShadow: "0px 0px 3px 3px " + koneds_color_gray_40 + "40",
                },
                color: koneds_color_white_100,
            },
        },
        MuiAvatar: {
            colorDefault: {
                backgroundColor: koneds_color_blue_base,
            },
        },
        MuiMenuItem: {
            root: {
                color: koneds_color_gray_80,
                backgroundColor: koneds_color_white_100,
            },
        },
        MuiListItem: {
            root: {
                "&$selected": {
                    color: koneds_color_blue_base,
                    backgroundColor: koneds_color_white_100,
                    fontWeight: 600,
                },
            },
        },
        MuiAppBar: {
            colorPrimary: {
                color: koneds_color_white_100,
                backgroundColor: koneds_color_blue_base,
            },
            colorSecondary: {
                color: koneds_color_gray_80,
                backgroundColor: koneds_color_white_100,
            },
        },
        MuiCssBaseline: {
            "@global": {
                "@font-face": [koneFont],
            },
        },
        MuiBottomNavigationAction: {
            root: {
                "&$selected": {
                    color: koneds_color_white_100,
                    backgroundColor: koneds_color_blue_base,
                    "& path": {
                        fill: koneds_color_white_100,
                    },
                },
            },
        },
    },
});
exports.default = theme;
