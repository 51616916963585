import "swagger-ui-react/swagger-ui.css";
import "./index.less";

import { PropsWithChildren, useEffect, useRef, useState } from "react";
import Swagger from "swagger-ui-react";
import axios from "axios";
import { CssBaseline } from "@material-ui/core";
import {
  createStyles,
  makeStyles,
  Theme,
  ThemeProvider,
} from "@material-ui/core/styles";
import {
  AuthenticatorProps,
  theme,
  WithAuthenticator,
  WithAuthenticatorProps,
} from "@kone/ui-library";

import Loading from "../../components/Loading";
import HeaderBar from "../../components/HeaderBar";

interface SwaggerInstance {
  system: {
    preauthorizeApiKey: (name: string, value: string) => void;
  };
}

const REDIRECT_URL = process.env.REACT_APP_SWAGGER_URL || "";
const useStyles = makeStyles((t: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    // necessary for content to be below app bar
    toolbar: t.mixins.toolbar,
    content: {
      flexGrow: 1,
    },
    filterSelection: {
      flexDirection: "row",
    },
  })
);

const APPLICATION_BACKEND_URL = process.env.REACT_APP_SWAGGER_API_URL || "";

const SwaggerUI = (
  props: PropsWithChildren<WithAuthenticatorProps>
): JSX.Element => {
  const [jwtToken, setJwtToken] = useState("");
  const [swaggerSpec, setSwaggerSpec] = useState({});
  const [userName, setUserName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(true);

  const swaggerElm = useRef(null);
  const classes = useStyles(theme);

  const updateSwaggerSpec = async () => {
    try {
      const authResponse = await props.acquireToken(true);

      const {
        accessToken,
        account: { userName },
      } = await props.acquireToken(true);

      if (accessToken) {
        setJwtToken(accessToken);
        setUserName(userName);
        setIsAuthenticated(true);

        const { data } = await axios.get(`${APPLICATION_BACKEND_URL}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setSwaggerSpec(data);
      }
    } catch (error) {
      console.error("updateSwaggerSpec", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    updateSwaggerSpec();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const swaggerInstance: SwaggerInstance =
      swaggerElm?.current || ({} as SwaggerInstance);
    swaggerInstance.system.preauthorizeApiKey("JWTAuth", jwtToken);
  }, [isLoading, jwtToken]);

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        <HeaderBar
          handleSidebarToggle={() => undefined}
          isAuthenticated={isAuthenticated}
          onSignOut={props.onSignOut}
          sidemenuWidth={0}
          title="API Documentation"
          userMenuTitle={userName}
        />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Swagger ref={swaggerElm} url="" spec={swaggerSpec} />
          {isLoading && <Loading />}
        </main>
      </div>
    </ThemeProvider>
  );
};

export { SwaggerUI as PureComponent };
export default WithAuthenticator(SwaggerUI, {
  clientId: "c49d3833-ee3c-4bfa-8fa8-d7566b7ec0fd",
  authority:
    "https://login.microsoftonline.com/2bb82c64-2eb1-43f7-8862-fdc1d2333b50",
  redirectUri: REDIRECT_URL,
  validateAuthority: true,
  postLogoutRedirectUri: REDIRECT_URL,
  navigateToLoginRequestUrl: false,
} as AuthenticatorProps);
